import $ from 'jquery';
import SelectionListOption from './SelectionListOption';
import AjaxLookup from './AjaxLookup';

// AutoSuggest class
function AutoSuggest(settings) {
    AjaxLookup.call(this, settings); // Extends AjaxLookup

    var emptyObj = { typeahead: {} }; // Empty Auto-Suggest object

    if (this.elementId.includes("$rowindex$"))
        return emptyObj;

    this.input = this.form.getElement("sv_" + this.elementId); // User input
    if (!this.input)
        return emptyObj;

    var self = this,
        $input = $(this.input),
        $element = $(this.element),
        timer;

    // Properties
    this.minWidth = settings.minWidth;
    this.maxHeight = settings.maxHeight;
    this.highlight = settings.highlight;
    this.hint = settings.hint;
    this.minLength = settings.minLength;
    this.templates = Object.assign({}, settings.templates); // Clone
    this.trigger = settings.trigger; // For loading more results
    this.delay = settings.delay; // For loading more results
    this.debounce = settings.debounce;
    this.display = settings.display || "text";
    this.forceSelection = settings.forceSelect;
    this.$input = $input;
    this.$element = $element;

    // Save initial option
    if ($input.val() && $element.val())
        this.element.add($element.val(), $input.val(), true);

    // Set the selected item to the actual field
    this.setValue = function(v) {
        v = v || $input.val();
        let index = this.element.options.findIndex(option => option.text == v);
        if (index < 0) { // Not found in results
            if (this.forceSelection && v) { // Force selection and query not empty => error
                $input.typeahead("val", "").attr("placeholder", ew.language.phrase("ValueNotExist")).addClass("is-invalid");
                $element.val("").trigger("change");
                return;
            }
        } else { // Found in results
            this.element.options[index].selected = true;
            if (!/s(ea)?rch$/.test(this.formElement.id) || this.forceSelection) // Force selection or not search form
                v = this.element.options[index].value; // Replace the display value by Link Field value
        }
        if (v !== $element.attr("value"))
            $element.attr("value", v).trigger("change"); // Set value to the actual field
    };

    // Transform suggestion
    this.transform = function(data) {
        let results = AjaxLookup.prototype.transform.call(this, data);
        this.element.options = results.map(item => new SelectionListOption(item.lf || item[0], self.formatResult(item)));
        return this.element.options;
    };

    // Get suggestions by Ajax
    this.source = function(query, syncResults, asyncResults) {
        if (timer)
            timer.cancel();
        timer = $.later(self.debounce, null, function() {
            self.recordCount = 0; // Reset
            var settings = self.prepare(query);
            $.ajax(settings).done(function(data) {
                asyncResults(self.transform(data));
            });
        });
    };

    // Get current suggestion count
    this.count = function() {
        return self.typeahead.menu.$node.find(".tt-suggestion.tt-selectable").length;
    };

    // Get more suggestions by Ajax
    this.more = function() {
        var $body = $("body");
        $body.css("cursor", "wait");
        var ta = self.typeahead, query = ta.menu.query, dataset = ta.menu.datasets[0];
        var start = self.count();
        var settings = self.prepare(query, start);
        $.ajax(settings).done(function(data) {
            data = self.transform(data);
            dataset._append(query, data);
            ta.menu.$node.find(".tt-dataset").scrollTop(dataset.$lastSuggestion.outerHeight() * start);
        }).always(function() {
            $body.css("cursor", "default");
        });
    };

    // Add events
    $input.on("typeahead:select", function(e, d) {
        self.setValue(d[self.display]);
    }).on("change", function(e) {
        var ta = $input.data("tt-typeahead");
        if (ta && ta.isOpen() && !ta.menu.empty()) {
            var $item = ta.menu.getActiveSelectable();
            if ($item) { // A suggestion is highlighted
                let i = $item.index(),
                    val = self.element.options[i].text;
                $input.typeahead("val", val);
            }
        }
        self.setValue();
    }).on("blur", function(e) { // "change" fires before blur
        var ta = $input.data("tt-typeahead");
        if (ta && ta.isOpen())
            ta.menu.close();
    }).on("focus", function(e) {
        $input.attr("placeholder", $input.data("placeholder")).removeClass("is-invalid");
    });

    // Option template ("suggestion" template)
    var tpl = self.list.template || self.templates["suggestion"];
    if (tpl && $.isString(tpl))
        tpl = $.templates(tpl);
    if (tpl)
        self.templates["suggestion"] = tpl.render.bind(tpl);

    // Save
    $element.data("autosuggest", this);

    // Create Typeahead
    $(function() {
        // Typeahead options and dataset
        var options = {
            highlight: self.highlight,
            minLength: self.minLength,
            hint: self.hint,
            trigger: self.trigger,
            delay: self.delay
        };
        var dataset = {
            name: self.form.id + "-" + self.elementId,
            source: self.source,
            templates: self.templates,
            display: self.display,
            limit: self.limit,
            async: true
        };
        var args = [options, dataset];
        // Trigger "typeahead" event
        $element.trigger("typeahead", [args]);
        self.limit = dataset.limit;
        // Create Typeahead
        $input.typeahead.apply($input, args);
        $input.on("typeahead:rendered", function() {
            var $node = self.typeahead.menu.$node;
            var $more = $node.find(".tt-more").html(ew.language.phrase("More"));
            if (arguments.length > 1 && // Arguments: event, suggestion, suggestion, ...
                self.recordCount > self.count()) {
                $more.one(options.trigger, function(e) {
                    setTimeout(function() {
                        self.more();
                    }, options.delay);
                    e.preventDefault();
                    e.stopPropagation();
                });
            } else {
                $more.hide();
            }
        });
        $input.off("blur.tt");
        self.typeahead = $input.data("tt-typeahead");
        var $menu = self.typeahead.menu.$node.css("z-index", 1000);
        if (self.minWidth)
            $menu.css("min-width", self.minWidth);
        var $dataset = $menu.find(".tt-dataset");
        var maxHeight = self.maxHeight ||
            (parseInt($dataset.css("line-height"), 10) + 6) * (dataset.limit + 1); // Match .tt-suggestion padding
        $dataset.css({ "max-height": maxHeight, "overflow-y": "auto" });
    });
}

AutoSuggest.prototype = Object.create(AjaxLookup.prototype);

export default AutoSuggest;