/**
 * Class Field
 */
export default class Field {
    name = "";
    validators = [];
    _error;
    _element;
    _checkbox;
    _validate = true;
    list;
    invalid;

    /**
     * Constructor
     * @param {string} fldvar Field variable name
     * @param {Function[]|Function} validators Validators
     * @param {bool} invalid Initial valid status (e.g. server side)
     */
    constructor(fldvar, validators, invalid) {
        this.name = fldvar;
        if (Array.isArray(validators)) {
            for (let validator of validators)
                this.addValidator(validator);
        } else if (typeof validators === "function") {
            this.addValidator(validators);
        }
        this.invalid = invalid;
    }

    /**
     * Add validator
     * @param {Function} validator Validator function
     */
    addValidator(validator) {
        if (typeof validator === "function")
            this.validators.push(validator);
    }

    /**
     * Get error
     * @returns {Object}
     */
    get error() {
        return this._error;
    }

    /**
     * Add error
     * @param {Object} err Error
     */
    addError(err) {
        if (err) {
            let error = this._error ?? {};
            this._error = { ...error, ...err };
            this.invalid = true;
        }
    }

    /**
     * Clear all errors
     */
    clearErrors() {
        this._error = null;
        this.invalid = false;
    }

    /**
     * Clear all validators
     */
    clearValidators() {
        this.validators = [];
    }

    /**
     * Get error message
     * @returns {string} HTML
     */
    get errorMessage() {
        if (this._error) {
            return Array.from(Object.values(this._error)).join("<br>");
        }
        return "";
    }

    /**
     * Check if the field should be validated
     */
    get shouldValidate() {
        return !this._checkbox || this._checkbox.checked;
    }

    /**
     * Set form element
     */
    set element(el) {
        this._element = el;
        this._checkbox = this._element?.id?.match(/^[xy]_/) ? document.getElementById(this._element.id.replace(/^[xy]_/, "u_")) : null; // Find the checkbox for the field in Update page
    }

    /**
     * Get form element
     * @returns {HTMLElement|HTMLElement[]}
     */
    get element() {
        return this._element;
    }

    /**
     * Get field value from form element
     * @returns {string|Array}
     */
    get value() {
        return this._element ? ew.getValue(this._element) : "";
    }

    /**
     * Validate field value
     * @returns {boolean}
     */
    validate() {
        let result = true;
        this.clearErrors(); // Reset error
        if (this._element && this.shouldValidate) {
            if (Array.isArray(this.validators)) {
                for (let validator of this.validators) {
                    let err = validator(this._element);
                    if (err !== false) {
                        this.addError(err);
                        result = false;
                    }
                }
                this.updateFeedback();
            }
        }
        return result;
    }

    /**
     * Update the error message to feedback element
     */
    updateFeedback() {
        let err = this.errorMessage;
        if (this._element && err) {
            jQuery(this._element).closest("[id^=el_], .form-group").find(".invalid-feedback").html(err);
            ew.setInvalid(this._element);
        }
    }

    /**
     * Set focus
     */
    focus() {
        if (this._element)
            ew.setFocus(this._element);
    }
}