import $ from 'jquery';
import MultiPage from "./multipage";
import * as Validators from './Validators';
import Form from "./Form";
import * as functions from './functions';

ew.IS_SCREEN_SM_MIN = window.matchMedia("(min-width: 768px)").matches; // Should matches $screen-sm-min
ew.MOBILE_DETECT = new MobileDetect(window.navigator.userAgent);
ew.IS_MOBILE = !!ew.MOBILE_DETECT.mobile();
ew.IS_IE = ew.MOBILE_DETECT.version("MSIE") > 0;

// Charts
window.exportCharts = {}; // Per window
window.drillDownCharts = {}; // Per window

// Init spinner
ew.addSpinner();

// Extend
Object.assign(ew, { MultiPage, Form, Validators }, functions);

var $document = $(document);

// Init document
loadjs.ready("load", function () {
	$.views.settings.debugMode(ew.DEBUG);
	ew.setSessionTimer();
	ew.initPage();
	$("#ew-modal-dialog").on("load.ew", ew.initPage);
	$("#ew-add-opt-dialog").on("load.ew", ew.initPage);
	var hash = ew.currentUrl.searchParams.get("hash");
	if (hash)
		$("html, body").animate({ scrollTop: $("#" + hash).offset().top }, 800);
	ew.removeSpinner();
	$document.trigger("load");
});

// Default "addoption" event (fired before adding new option to selection list)
$document.on("addoption", function (e, args) {
	var row = args.data; // New row to be validated
	var arp = args.parents; // Parent field values
	for (var i = 0, cnt = arp.length; i < cnt; i++) { // Iterate parent values
		var p = arp[i];
		if (!p.length) // Empty parent
			//continue; // Allow
			return args.valid = false; // Disallow
		var val = row["ff" + ((i > 0) ? i + 1 : "")]; // Filter fields start from the 6th field
		if (!$.isUndefined(val) && !p.includes(String(val))) // Filter field value not in parent field values
			return args.valid = false; // Returns false if invalid
	}
});

// Fix z-index of multiple modals
$document.on("show.bs.modal", ".modal", function () {
	var zIndex = 1050 + $(".modal:visible").length;
	$(this).css("z-index", zIndex);
	setTimeout(function () {
		$(".modal-backdrop").not(".modal-stack").css("z-index", zIndex - 1).addClass("modal-stack");
	}, 0);
});

// Fix scrolling of multiple modals
$document.on("hidden.bs.modal", ".modal", function () {
	$(".modal:visible").length && $("body").addClass("modal-open");
});