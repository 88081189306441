import $ from 'jquery';

/**
 * User level ID validator
 */
export function userLevelId(el) {
    if (el && !ew.checkInteger(el.value))
        return { userLevelId: ew.language.phrase("UserLevelIDInteger") };
    var level = parseInt(el.value, 10);
    if (level < 1)
        return { userLevelId: ew.language.phrase("UserLevelIDIncorrect") };
    return false;
}

/**
 * User level name validator
 * @param {string} id User ID Field input element ID
 */
export function userLevelName(id) {
    return function(el) {
        let elId = document.getElementById("x_" + id);
        if (elId && el) {
            let name = el.value.trim(),
                level = parseInt(elId.value.trim(), 10);
            if (level === 0 && !ew.sameText(name, "Default")) {
                return { userLevelName: ew.language.phrase("UserLevelDefaultName") };
            } else if (level === -1 && !ew.sameText(name, "Administrator")) {
                return { userLevelName: ew.language.phrase("UserLevelAdministratorName") };
            } else if (level === -2 && !ew.sameText(name, "Anonymous")) {
                return { userLevelName: ew.language.phrase("UserLevelAnonymousName") };
            } else if (level > 0 && ["anonymous", "administrator", "default"].includes(name.toLowerCase())) {
                return { userLevelName: ew.language.phrase("UserLevelNameIncorrect") };
            }
        }
        return false;
    };
}

/**
 * Required validator
 */
export function required(fieldName) {
    return function(el) {
        let $el = $(el),
            $p = $el.closest("#r_" + $el.data("field")?.substr(2)); // Find the row
        if (!$p[0])
            $p = $el.closest("[id^=el]"); // Find the span
        if ($p.css("display") == "none") { // Hidden by .visible()
            return false;
        }
        if (el && !ew.hasValue(el)) {
            return { required: ew.language.phrase("EnterRequiredField").replace("%s", fieldName) };
        }
        return false;
    }
}

/**
 * File required validator
 */
export function fileRequired(fieldName) {
    return function(el) {
        let elFn = document.getElementById("fn_" + el.id);
        if (elFn && !ew.hasValue(elFn)) {
            return { fileRequired: ew.language.phrase("EnterRequiredField").replace("%s", fieldName) };
        }
        return false;
    }
}

/**
 * Mismatch password validator
 */
export function mismatchPassword(el) {
    let id;
    if (el.id.startsWith("c_")) // Confirm Password field in Register page
        id = el.id.replace(/^c_/, "x_");
    else if (el.id == "cpwd") // Change Password page
        id = "npwd";
    let elPwd = document.getElementById(id);
    if (el.value !== elPwd.value) {
        return { mismatchPassword: ew.language.phrase("MismatchPassword") };
    }
    return false;
}

/**
 * Between validator
 */
export function between(el) {
    let x, z;
    if (el.id.startsWith("y_")) {
        x = document.getElementById(el.id.replace(/^y_/, "x_"));
        z = document.getElementById(el.id.replace(/^y_/, "z_"));
    }
    if (ew.hasValue(x) && $(z).val() == "BETWEEN" && !ew.hasValue(el)) {
        return { between: ew.language.phrase("EnterValue2") };
    }
    return false;
}

/**
 * Password strength validator
 */
export function passwordStrength(el) {
    let $el = $(el);
    if (!ew.isMaskedPassword(el) && $el.hasClass("ew-password-strength") && !$el.data("validated")) {
        return { passwordStrength: ew.language.phrase("PasswordTooSimple") };
    }
    return false;
}

/**
 * User name validator
 */
export function username(raw) {
    return function(el) {
        if (!raw && el.value.match(new RegExp('[' + ew.escapeRegExChars(ew.INVALID_USERNAME_CHARACTERS) + ']')))
            return { username: ew.language.phrase("InvalidUsernameChars") };
        return false;
    }
}

/**
 * Password validator
 */
export function password(raw) {
    return function(el) {
        if (!raw && !ew.ENCRYPTED_PASSWORD && el.value.match(new RegExp('[' + ew.escapeRegExChars(ew.INVALID_PASSWORD_CHARACTERS) + ']')))
            return { password: ew.language.phrase("InvalidPasswordChars") };
        return false;
    }
}

/**
 * Email validator
 */
export function email(el) {
    let value = ew.getValue(el);
    if (!ew.checkEmail(value)) {
        return { email: ew.language.phrase("IncorrectEmail") };
    }
    return false;
}

/**
 * Emails validator
 */
export function emails(cnt, err) {
    return function(el) {
        let value = ew.getValue(el);
        if (!ew.checkEmails(value, cnt)) {
            return { email: err };
        }
        return false;
    }
}

/**
 * DateTime validator
 * @param {number} dateFormat DateTime format ID
 */
export function datetime(dateFormat) {
    return function(el) {
        let fn,
            phraseId,
            value = ew.getValue(el),
            newSubStr = ew.DATE_SEPARATOR;
        if ([12, 15, 115].includes(dateFormat)) {
            fn = ew.checkShortDate;
            phraseId = "IncorrectShortDateYMD";
        } else if ([5, 9, 109].includes(dateFormat)) {
            fn = ew.checkDate;
            phraseId = "IncorrectDateYMD";
        } else if ([14, 17, 117].includes(dateFormat)) {
            fn = ew.checkShortEuroDate;
            phraseId = "IncorrectShortDateDMY";
        } else if ([7, 11, 111].includes(dateFormat)) {
            fn = ew.checkEuroDate;
            phraseId = "IncorrectDateDMY";
        } else if ([13, 16, 116].includes(dateFormat)) {
            fn = ew.checkShortUSDate;
            phraseId = "IncorrectShortDateMDY";
        } else if ([6, 10, 110].includes(dateFormat)) {
            fn = ew.checkUSDate;
            phraseId = "IncorrectDateMDY";
        } else { // Default date format
            fn = ew.checkDateDef;
            phraseId = "IncorrectDate";
            newSubStr = ew.DATE_FORMAT;
        }
        if (fn && !fn(value)) {
            return { datetime: ew.language.phrase(phraseId).replace(/%s/g, newSubStr) };
        }
       return false;
    };
}

/**
 * Time validator
 */
export function time(el) {
    let value = ew.getValue(el);
    if (!ew.checkTime(value)) {
        return { time: ew.language.phrase("IncorrectTime") };
    }
    return false;
}

/**
 * Float validator
 */
export function float(el) {
    let value = ew.getValue(el);
    if (!ew.checkNumber(value)) {
        return { time: ew.language.phrase("IncorrectFloat") };
    }
    return false;
}

/**
 * Range validator
 * @param {number} min Min value
 * @param {number} max Max value
 */
export function range(min, max) {
    return function(el) {
        let value = ew.getValue(el);
        if (!ew.checkRange(value, min, max)) {
            return { range: ew.language.phrase("IncorrectRange").replace("%1", min).replace("%2", max) };
        }
       return false;
    };
}

/**
 * Integer validator
 */
export function integer(el) {
    let value = ew.getValue(el);
    if (!ew.checkInteger(value)) {
        return { integer: ew.language.phrase("IncorrectInteger") };
    }
    return false;
}

/**
 * US phone validator
 */
export function phone(el) {
    let value = ew.getValue(el);
    if (!ew.checkPhone(value)) {
        return { phone: ew.language.phrase("IncorrectPhone") };
    }
    return false;
}

/**
 * US ZIP validator
 */
export function zip(el) {
    let value = ew.getValue(el);
    if (!ew.checkZip(value)) {
        return { zip: ew.language.phrase("IncorrectZip") };
    }
    return false;
}

/**
 * Credit card validator
 */
export function creditCard(el) {
    let value = ew.getValue(el);
    if (!ew.checkCreditCard(value)) {
        return { creditCard: ew.language.phrase("IncorrectCreditCard") };
    }
    return false;
}

/**
 * US SSN validator
 */
export function ssn(el) {
    let value = ew.getValue(el);
    if (!ew.checkSsn(value)) {
        return { ssn: ew.language.phrase("IncorrectSSN") };
    }
    return false;
}

/**
 * GUID validator
 */
export function guid(el) {
    let value = ew.getValue(el);
    if (!ew.checkGuid(value)) {
        return { guid: ew.language.phrase("IncorrectGUID") };
    }
    return false;
}
/**
 * Regular expression validator
 * @param {string} pattern Regular expression pattern
 */
export function regex(pattern) {
    return function(el) {
        let value = ew.getValue(el);
        if (!ew.checkByRegEx(value, pattern)) {
            return { regex: ew.language.phrase("IncorrectField") };
        }
       return false;
    };
}

/**
  * Custom validator
  * @param {*} fn Function(value, ...args)
  * @param  {...any} args Additional arguments for the function
  */
export function custom(fn, ...args) {
    return function(el) {
        if (typeof fn == "function") {
            let value = ew.getValue(el);
            if (fn(value, ...args))
                return { custom: ew.language.phrase("IncorrectField") };
        }
        return false;
    };
}

/**
 * Captcha validator
 */
export function captcha(el) {
    if (el && !ew.hasValue(el)) {
        return { captcha: ew.language.phrase("EnterValidateCode") };
    }
    return false;
}

/**
 * reCaptcha validator
 * @param {number} id reCaptcha ID
 */
export function recaptcha(el) {
    if (el && !ew.hasValue(el) && grecaptcha?.getResponse(el.dataset.id) === "") {
        return { recaptcha: ew.language.phrase("ClickReCaptcha") };
    }
    return false;
}