import $ from 'jquery';

// AjaxLookup class
export default class AjaxLookup {
    _isAutoSuggest = null;

    /**
     * Constructor
     * @param {Object} settings Settings
     * @param {string} settings.id - Input element ID
     * @param {string|Form} settings.form - Form of the input element
     * @param {Number} settings.limit - Options per page
     * @param {Object} settings.data - Data submitted by Ajax
     */
    constructor(settings) {
        this.elementId = settings.id; // Id
        this.form = settings.form; // Form
        if ($.isString(this.form)) // Form is string => Form id
            this.form = ew.forms.get(this.form);
        this.element = this.form.getElement(this.elementId); // Actual HTML element
        this.formElement = this.form.getElement(); // HTML form or DIV
        this.list = this.form.getList(this.elementId);
        let m = this.elementId.match(/^[xy](\d*|\$rowindex\$)_/),
            rowindex = m ? m[1] : "";
        this.parentFields = this.list.parentFields.slice() // Clone
            .map(pf => (pf.split(" ").length == 1) ? pf.replace(/^x_/, "x" + rowindex + "_") : pf); // Parent field in the same table, add row index
        this.limit = settings.limit;
        this.debounce = settings.debounce;
        this.data = settings.data;
        this.recordCount = 0;
    }

    /**
     * Is AutoSuggest
     */
    get isAutoSuggest() {
        if (this._isAutoSuggest === null)
            this._isAutoSuggest = ew.isAutoSuggest(this.element);
        return this._isAutoSuggest;
    }

    /**
     * Format display value
     * @param {Array} opt Option
     */
    formatResult(opt) {
        if (this.list.template && !this.isAutoSuggest) {
            return this.list.template.render(opt, ew.jsRenderHelpers);
        } else {
            return ew.displayValue(opt, this.element) || opt[0];
        }
    }

    /**
     * Generate request
     */
    generateRequest() {
        var data = Object.assign({}, this.data, {
            name: this.element.name,
            page: this.list.page,
            field: this.list.field,
            ajax: "autosuggest",
            language: ew.LANGUAGE_ID
        }, ew.getUserParams("#p_" + this.elementId, this.formElement));
        if (this.parentFields.length > 0) {
            this.parentFields.forEach((pf, i) => {
                let arp = ew.getOptionValues(pf, this.formElement);
                data["v" + (i + 1)] = arp.join(ew.MULTIPLE_OPTION_SEPARATOR);
            });
        }
        return data;
    }

    /**
     * Get URL
     */
    getUrl(query, start) {
        let params = new URLSearchParams({
            q: query,
            n: this.limit,
            rnd: ew.random(),
            start: $.isNumber(start) ? start : -1
        });
        return ew.getApiUrl(ew.API_LOOKUP_ACTION, params.toString());
    }

    /**
     * Prepare URL and data for sending request
     * @param {string} query Search term
     * @param {Number} start Start page
     */
    prepare(query, start) {
        return {
            url: this.getUrl(query, start),
            type: "POST",
            dataType: "json",
            data: this.generateRequest()
        };
    }

    /**
     * Transform options (virtual)
     * @param {Object[]} data Data from server
     */
    transform(data) {
        let results = [];
        if (data && data.result == "OK") {
            this.recordCount = data.totalRecordCount;
            results = data.records;
        }
        return results;
    }

}
