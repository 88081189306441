import $ from 'jquery';

/**
 * Select2 decorator for results adapter
 */
export default function(Utils) {

    class Select2CustomOption {

        render(decorated) {
            var $results = $(
              '<div class="select2-results__options ' + this.options.get('containerClass') + '" role="listbox"></div>'
            ); //***

            if (this.options.get('multiple')) {
              $results.attr('aria-multiselectable', 'true');
            }

            this.$results = $results;

            return $results;
        };

        displayMessage(decorated, params) {
            var escapeMarkup = this.options.get('escapeMarkup');

            this.clear();
            this.hideLoading();

            var $message = $(
              '<div role="alert" aria-live="assertive"' +
              ' class="select2-results__option"></div>'
            ); //***

            if (params.message.includes("<") && params.message.includes(">")) { // HTML //***
              $message.append(params.message);
            } else {
              var message = this.options.get('translations').get(params.message);
              $message.append(
                escapeMarkup(
                  message(params.args)
                )
              );
            }

            $message[0].className += ' select2-results__message';

            this.$results.append($message);
        };

        append(decorated, data) {
            this.hideLoading();

            var $options = [];

            if (data.results == null || data.results.length === 0) {
              if (this.$results.children().length === 0) {
                if (this.$element.data("updating")) {
                  this.trigger('results:message', {
                    message: '<div class="spinner-border spinner-border-sm text-primary ew-select-spinner" role="status"><span class="sr-only">' + ew.language.phrase('Loading') + '</span></div> ' + ew.language.phrase('Loading')
                  });
                  this.$element.one("updatedone", () => this.$element.select2("close").select2("open"));
                } else {
                  this.trigger('results:message', {
                    message: 'noResults'
                  });
                }
              }

              return;
            }

            data.results = this.sort(data.results);

            //***
            var cols = this.options.get('columns'),
                len = data.results.length,
                $row = this.$results.find("." + this.options.get('rowClass')).last();

            for (var d = 0; d < data.results.length; d++) {
                var item = data.results[d];

                var $option = this.option(item);

                if (!$row.length || $row.children().length == cols) { // Add new row
                    $row = $('<div class="' + this.options.get('rowClass') + '"></div>');
                    this.$results.append($row);
                }

                $row.append($option);

                if (d == len - 1) { // Last
                    var cnt = cols - $row.children().length;
                    for (var i = 0; i < cnt ; i++)
                        $row.append('<div class="' + this.options.get('colClass') + '"></div>');
                }
            }

        };

        option(decorated, data) {
            // var option = document.createElement('li');
            var option = document.createElement('div'); //***
            option.className = 'select2-results__option ' + this.options.get('cellClass'); //***

            var attrs = {
              'role': 'option',
              'aria-selected': 'false'
            };

            var matches = window.Element.prototype.matches ||
              window.Element.prototype.msMatchesSelector ||
              window.Element.prototype.webkitMatchesSelector;

            if ((data.element != null && matches.call(data.element, ':disabled')) ||
                (data.element == null && data.disabled)) {
              delete attrs['aria-selected'];
              attrs['aria-disabled'] = 'true';
            }

            if (data.id == null) {
              delete attrs['aria-selected'];
            }

            if (data._resultId != null) {
              option.id = data._resultId;
            }

            if (data.title) {
              option.title = data.title;
            }

            // if (data.children) { //***
            //   attrs.role = 'group';
            //   attrs['aria-label'] = data.text;
            //   delete attrs['aria-selected'];
            // }

            for (var attr in attrs) {
              var val = attrs[attr];

              option.setAttribute(attr, val);
            }

            // if (data.children) { //***
            //   var $option = $(option);

            //   var label = document.createElement('strong');
            //   label.className = 'select2-results__group';

            //   var $label = $(label);
            //   this.template(data, label);

            //   var $children = [];

            //   for (var c = 0; c < data.children.length; c++) {
            //     var child = data.children[c];

            //     var $child = this.option(child);

            //     $children.push($child);
            //   }

            //   var $childrenContainer = $('<ul></ul>', {
            //     'class': 'select2-results__options select2-results__options--nested'
            //   });

            //   $childrenContainer.append($children);

            //   $option.append(label);
            //   $option.append($childrenContainer);
            // } else {
              this.template(data, option);
            // }

            Utils.StoreData(option, 'data', data);

            return option;
        };

    }

    return Select2CustomOption;
}