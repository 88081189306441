import FormBase from "./FormBase";
import Field from "./Field";

/**
 * Class Form
 */
export default class Form extends FormBase {
    row = {};
    fields = {};
    rowIndex;
    value;
    _error;

    /**
     * Constructor
     * @param {string} id Form ID
     * @param {string} pageId Page ID
     */
    constructor(id, pageId) {
        super(id, pageId);
    }

    /**
     * Add field
     * @param {string} fldvar Field variable name
     * @param {Function[]} validators Validators
     * @param {bool} invalid Invalid
     */
    addField(fldvar, validators, invalid) {
        if (!(fldvar in this.fields))
            this.fields[fldvar] = new Field(fldvar, validators, invalid);
    }

    /**
     * Get field
     * @param {string} fldvar Field variable name
     * @returns Field
     */
    getField(fldvar) {
        return this.fields[fldvar];
    }

    /**
     * Add fields by field definitions
     * @param {Array} fields
     */
    addFields(fields) {
        if (Array.isArray(fields)) {
            for (let field of fields) {
                if (Array.isArray(field)) {
                    this.addField.apply(this, field);
                }
            }
        }
    }

    /**
     * Add error
     * @param {string} fldvar Field variable name
     * @param {Object} err Error
     */
    addError(fldvar, err) {
        if (err) {
            this._error = this._error ?? {};
            this._error[fldvar] = err;
        }
    }

    /**
     * Add custom error
     * @param {string} fldvar Field variable name 
     * @param {string} msg Error message 
     */
    addCustomError(fldvar, msg) {
        if (fldvar in this.fields) {
            let field = this.fields[fldvar],
                err = { custom: msg };
            field.addError(err);
            field.updateFeedback();
            this.addError(fldvar, err);
        }
        return false;
    }

    /**
     * Get error
     */
    get error() {
        return this._error;
    }

    /**
     * Set focus to the first field with error
     */
    focus() {
        for (let [fldvar, field] of Object.entries(this.fields)) {
            if (field.invalid || this._error && this._error[fldvar]) {
                field.focus();
                this.makeVisible(field.element);
                break;
            }
        }
    }

    /**
     * Make the form visible
     * @param {HTMLElement} el Focused element 
     */
    makeVisible(el) {
        if (this.multiPage) { // Multi-page
            this.multiPage.gotoPageByElement(el);
        } else if (this.$element.is("div")) { // Multiple Master/Detail
            let $pane = this.$element.closest(".tab-pane");
            if ($pane[0] && !$pane.hasClass("active"))
                $pane.closest(".tabbable, .ew-nav-tabs").find("a[data-toggle=tab][href='#" + $pane.attr("id") + "']").click();
        }
    }

    /**
     * Validate all fields of the specified row
     * @param {number} rowIndex Row index
     */
    validateFields(rowIndex) {
        this.rowIndex = rowIndex ?? "";
        this.row = {};
        this._error = null; // Reset
        let result = true;
        for (let field of Object.values(this.fields)) {
            field.element = this.getElements(`x${this.rowIndex}_${field.name}`); // Set element
            if (!field.element)
                field.element = this.getElements(`x${this.rowIndex}_${field.name}[]`); // Field with []
            if (!field.element)
                field.element = this.getElements(field.name); // Field by name directly (e.g. email form)
            this.row[field.name] = field.value; // Get field value
            if (field.element && !field.validate()) { // Invalid field value
                this.addError(field.name, field.error);
                result = false;
            }
        }
        // Save the field values of the row
        if (!this.value) {
            this.value = { ...this.row };
        } else {
            if (!Array.isArray(this.value))
                this.value = [this.value];
            let index = parseInt(rowIndex, 10) || 0;
            index = (index > 1) ? index - 1 : 0;
            this.value[index] = { ...this.row };
        }
        this.focus();

        return result;
    }

    /**
     * Set invalid fields of the specified row
     * @param {number} rowIndex Row index
     */
    setInvalid(rowIndex) {
        this.rowIndex = rowIndex ?? "";
        for (let field of Object.values(this.fields)) {
            if (!field.invalid)
                continue;
            field.element = this.getElements(`x${this.rowIndex}_${field.name}`); // Set element
            if (!field.element)
                field.element = this.getElements(`x${this.rowIndex}_${field.name}[]`); // Fields with []
            if (!field.element)
                field.element = this.getElements(field.name); // Fields without prefix (e.g. email form)
            ew.setInvalid(field.element);
        }
    }
}